import { get, mapValues } from 'lodash';

import { buildIstatAccommodation } from '../../helpers';
import {
  Entries,
  GuestStatusInfo,
  IIstatGuestsCard,
  IstatPageAlerts,
} from '../../models';

import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      const guestsStatus = get(action.payload, 'items.statuses.guest', []);
      const guestsStatusToArray: GuestStatusInfo[] = Object.keys(
        guestsStatus,
      ).map((guest) => {
        return {
          ...guestsStatus[guest],
        };
      });
      const entries: Entries = mapValues(items.entries, (reservations) =>
        reservations.map((reservation) => {
          const {
            send_to_external,
            reservation_id: reservationId,
            accommodations,
            reference_number,
            departure_date,
            arrival_date,
            booker: { name: bookerName, surname: bookerSurname },
          } = reservation;

          const newAccommodations = buildIstatAccommodation(
            accommodations,
            guestsStatusToArray,
          );

          return {
            bookerName,
            bookerSurname,
            departure_date,
            arrival_date,
            reference_number,
            send_to_external,
            reservationId,
            atLeastOneGuestSent: newAccommodations.some(
              (accommodation) => accommodation.atLeastOneGuestSent,
            ),
            allGuestsSent: newAccommodations.every(
              (accommodation) => accommodation.allGuestsSent,
            ),
            accommodations: newAccommodations,
          } as unknown as IIstatGuestsCard;
        }),
      );

      let alerts: IstatPageAlerts[] = [];

      if (guestsStatusToArray.length) {
        alerts = guestsStatusToArray.some(
          (guest) => guest.reportedStatus !== 'sync',
        )
          ? []
          : [
              {
                messages: ['istat_all_guest_sent_message'],
                priority: 1,
                type: 'info',
              },
            ];
      }

      return {
        ...state,
        isLoading: false,
        error: null,
        entries,
        alerts,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_FAILURE: {
      const { error } = action.payload || {};
      return {
        ...state,
        isLoading: false,
        error,
      };
    }

    case ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_SUCCESS: {
      const { accommodationRoomId, availableGuests } = action.payload || {};

      return {
        ...state,
        isLoading: false,
        error: null,
        availableGuestType: {
          ...(state.availableGuestType || {}),
          [accommodationRoomId]: [...availableGuests],
        },
      };
    }
    case ActionTypes.SEND_ROOM_GUEST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.SEND_ROOM_GUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.SEND_ROOM_GUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

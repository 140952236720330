import { Entries, IstatPageAlerts } from '@app/models';

export interface State {
  isLoading?: boolean;
  error?: any;
  entries: Entries;
  alerts: IstatPageAlerts[];
  availableGuestType: {
    [key: number]: number[];
  };
}

export const initialState: State = {
  isLoading: false,
  error: null,
  entries: null,
  alerts: [],
  availableGuestType: null,
};

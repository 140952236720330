import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { environment } from '../../environments/environment';

import { LoadingModalService } from './loading-modal.service';

@Injectable({
  providedIn: 'root',
})
export class IstatGuestCardLoadingService extends LoadingModalService {
  /**
   * @override
   */
  imageUrl = `${environment.cdnUrl}images/loading/transfer-data.gif`;

  /**
   * @override
   */
  get message(): string {
    return this.translateService.instant(
      'istat_guest_card_send_loading_message',
    );
  }

  constructor(
    modalService: NzModalService,
    translateService: TranslateService,
  ) {
    super(modalService, translateService);
  }
}

import { Entries, IstatPageAlerts } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getAvailableCustomerTypes = (state: State) =>
  state.availableGuestType;

export const getEntries = (state: State): Entries => state.entries;

export const getAlerts = (state: State): IstatPageAlerts[] => state.alerts;

export const selectIstatGuestsCardState: MemoizedSelector<object, State> =
  createFeatureSelector<State>('istatGuestsCard');

export const selectIstatGuestsCardError: MemoizedSelector<object, any> =
  createSelector(selectIstatGuestsCardState, getError);

export const selectIstatGuestsCardIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectIstatGuestsCardState, getIsLoading);

export const selectEntries = createSelector(
  selectIstatGuestsCardState,
  getEntries,
);

export const selectAlerts = createSelector(
  selectIstatGuestsCardState,
  getAlerts,
);

export const selectAvailableCustomerTypeMap = createSelector(
  selectIstatGuestsCardState,
  getAvailableCustomerTypes,
);

import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IIstatGuestsCardEdit } from '@app/models/objects/istat-guests-card';
import { Action } from '@ngrx/store';

import { IstatGuestCardsData } from '../../models';
import { SearchParams } from '../../models/objects/search-params';
import { Hooks } from '../../helpers';

export enum ActionTypes {
  LOAD_REQUEST = '[Istat Guests Card] Load Request',
  LOAD_FAILURE = '[Istat Guests Card] Load Failure',
  LOAD_SUCCESS = '[Istat Guests Card] Load Success',

  UPDATE_REQUEST = '[Istat Guests Card] Update Request',
  UPDATE_FAILURE = '[Istat Guests Card] Update Failure',
  UPDATE_SUCCESS = '[Istat Guests Card] Update Success',

  SEND_ROOM_GUEST_REQUEST = '[Istat Guests Card] Send Room Guest Request',
  SEND_ROOM_GUEST_FAILURE = '[Istat Guests Card] Send Room Guest Failure',
  SEND_ROOM_GUEST_SUCCESS = '[Istat Guests Card] Send Room Guest Success',

  LOAD_AVAILABLE_GUEST_TYPE_REQUEST = '[Istat Guests Card] Load Available Guest Type Request',
  LOAD_AVAILABLE_GUEST_TYPE_FAILURE = '[Istat Guests Card] Load Available Guest Type Failure',
  LOAD_AVAILABLE_GUEST_TYPE_SUCCESS = '[Istat Guests Card] Load Available Guest Type Success',

  RESET_STATE = '[Istat Guests Card] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      housedWebGroupId: number;
      params: SearchParams;
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: {
      items: IstatGuestCardsData;
      pagination: IPagination;
    },
  ) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      eventId: number;
      request: IIstatGuestsCardEdit;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { event: Event }) {}
}

export class SendRoomGuestRequestAction implements Action {
  readonly type = ActionTypes.SEND_ROOM_GUEST_REQUEST;
  constructor(
    public payload: {
      housedWebGroupId: number;
      reference_date: string;
      action: 'download' | 'send';
      hooks: Hooks;
    },
  ) {}
}

export class SendRoomGuestFailureAction implements Action {
  readonly type = ActionTypes.SEND_ROOM_GUEST_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SendRoomGuestSuccessAction implements Action {
  readonly type = ActionTypes.SEND_ROOM_GUEST_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class LoadAvailableCustomerTypesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_REQUEST;
  constructor(
    public payload: {
      housedWebGroupId: number;
      accommodationRoomId: number;
    },
  ) {}
}

export class LoadAvailableCustomerTypesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_SUCCESS;
  constructor(
    public payload: {
      availableGuests: number[];
      accommodationRoomId: number;
    },
  ) {}
}

export class LoadAvailableCustomerTypesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_FAILURE;
  constructor(
    public payload: {
      error: any;
    },
  ) {}
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  | SendRoomGuestRequestAction
  | SendRoomGuestFailureAction
  | SendRoomGuestSuccessAction
  | ResetSuccessAction
  | LoadAvailableCustomerTypesRequestAction
  | LoadAvailableCustomerTypesSuccessAction
  | LoadAvailableCustomerTypesFailureAction;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '@app/core/helpers/params-generator';
import { forkJoin } from 'rxjs';

import { SetToExternalData } from '../models';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class IstatGuestsCardService {
  constructor(private http: HttpClient) {}

  load(housedWebGroupId: number, params: SearchParams) {
    return this.http.get(
      `properties/reporting/alloggiatiweb/${housedWebGroupId}?${generateSearchQuery(
        params,
      )}`,
    );
  }

  loadAvailableGuestType(
    housedWebGroupId: number,
    accommodationRoomId: number,
  ) {
    return this.http.get(
      `properties/alloggiatiweb/${housedWebGroupId}/reservation_accommodation_room/${accommodationRoomId}/available_guest_types`,
    );
  }

  sendRoomGuest({ housedWebGroupId, reference_date, action }) {
    return this.http.post(
      `properties/reporting/alloggiatiweb/${housedWebGroupId}?reference_date=${reference_date}&action=${action}`,
      {},
    );
  }

  setRoomGuestSend(reservationRooms: any[]) {
    return forkJoin(
      ...reservationRooms.map((room) =>
        this.http.post(
          `reservation/${room.reservation_id}/reservation_accommodation/${room.roomreservation_id}/toggle_guests`,
          room,
        ),
      ),
    );
  }

  setGuestSend(guestsDataToSend: SetToExternalData[]) {
    return forkJoin(
      ...guestsDataToSend.map((guest) =>
        this.http.post(
          `reservation/${guest.reservation_id}/reservation_accommodation/${guest.roomreservation_id}/toggle_guest/${guest.guest_id}`,
          guest,
        ),
      ),
    );
  }

  export(housedWebGroupId: number, params: SearchParams) {
    return this.http.post(
      `properties/reporting/alloggiatiweb/${housedWebGroupId}?${generateSearchQuery(
        params,
      )}`,
      {},
    );
  }
}

import { Injectable } from '@angular/core';
import { ICoreState } from '@app/core/+state/core.reducer';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { IstatGuestsCardService } from '@app/services/istat-guests-card.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { IstatGuestCardLoadingService } from '../../services/istat-guest-card-loading.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class IstatGuestsCardStoreEffects {
  constructor(
    private dataService: IstatGuestsCardService,
    private actions$: Actions,
    private store: Store<{ core: ICoreState }>,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private loadingModal: IstatGuestCardLoadingService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      withLatestFrom(this.store),
      switchMap(
        ([action, store]: [
          featureActions.LoadRequestAction,
          { core: ICoreState },
        ]) => {
          const { housedWebGroupId, params } = action.payload;

          return this.dataService.load(housedWebGroupId, params).pipe(
            map(
              (request: any) =>
                new featureActions.LoadSuccessAction({
                  items: request.data,
                  pagination: request.pagination,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          );
        },
      ),
    ),
  );

  loadAvailableGuestTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAvailableCustomerTypesRequestAction>(
        featureActions.ActionTypes.LOAD_AVAILABLE_GUEST_TYPE_REQUEST,
      ),
      mergeMap((action) => {
        const { housedWebGroupId, accommodationRoomId } = action.payload;

        return this.dataService
          .loadAvailableGuestType(housedWebGroupId, accommodationRoomId)
          .pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadAvailableCustomerTypesSuccessAction({
                  accommodationRoomId,
                  availableGuests: data.available_guest_types,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.LoadAvailableCustomerTypesFailureAction({
                  error,
                }),
              );
            }),
          );
      }),
    ),
  );

  sendRoomGuestRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SendRoomGuestRequestAction>(
        featureActions.ActionTypes.SEND_ROOM_GUEST_REQUEST,
      ),
      switchMap((action: featureActions.SendRoomGuestRequestAction) => {
        this.loadingModal.start();
        return this.dataService.sendRoomGuest(action.payload).pipe(
          effectHooks(action.payload.hooks),
          switchMap((response: IResponseSuccess) => {
            const { housedWebGroupId, reference_date } = action.payload;
            let errors = [];
            let message = '';
            Object.keys(response.data).forEach((key) => {
              message = get(response.data, [key, 'message'], '');
              errors = [
                ...errors,
                ...(get(response.data, [key, 'errors'], []) as {
                  message: string;
                }[]),
              ];
            });

            if (errors.length) {
              errors.forEach((error: { message: string }) => {
                this.notifications.push({
                  title: this.translate.instant('error'),
                  content: this.translate.instant(error.message),
                  type: 'error',
                });
              });
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: message
                  ? message
                  : this.translate.instant('notifications.generic_success'),
                type: 'success',
              });
            }

            this.loadingModal.stop();
            return [
              new featureActions.SendRoomGuestSuccessAction(),
              new featureActions.LoadRequestAction({
                housedWebGroupId,
                params: { reference_date },
              }),
            ];
          }),

          catchError((error) => {
            this.errorHandler.handle(error);
            this.loadingModal.stop();
            return of(new featureActions.SendRoomGuestFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
